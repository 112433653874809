import * as React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Tooltip,
  CircularProgress,
  IconButton,
} from "@mui/material";
import Permissioned, { actions, resources } from "../permissioned";
import { Pagination } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Link as RouteLink,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { EasaPageReducer } from "../reducers/autnhive";
import { Reducers } from "../reducers";
import * as easaActions from "../actions/easa";
import moment from "moment";
import { Visibility } from "@mui/icons-material";
import { getDuration } from "../services/helpers";
import { useSnackbar } from "notistack";

const LIMIT = 20;

export default function Tasks() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { domainTasks, retryResponse }: EasaPageReducer = useSelector<
    Reducers,
    EasaPageReducer
  >((state) => state.autnhive.easa);

  const [domainName, setDomainName] = useState<string>("");
  const jobDetails = localStorage.getItem("jobDetails");
  const domainDetails = localStorage.getItem("domainDetails");
  const [currentPage, setCurrentPage] = useState(1)
  const [parentJobId, setParentJobId] = useState("")

  useEffect(() => {
    if (domainDetails && domainDetails !== "") {
      const result = JSON.parse(domainDetails);
      setDomainName(result?.url);
    }
  }, [domainDetails]);

  useEffect(() => {
    if (jobDetails && jobDetails !== "") {
      const result = JSON.parse(jobDetails);
      if (domainTasks?.list?.length === 0) {
        dispatch(easaActions.fetchDomainTasksList(1, 20, result?.parentJobId));
        setParentJobId(result?.parentJobId)
      }
    }
  }, [jobDetails]);

  useEffect(() => {
    return () => {
      dispatch(easaActions.resetTasksStates());
    };
  }, [dispatch]);

  useEffect(() => {
    if(parentJobId.length > 0){
      if(retryResponse == "success"){
        dispatch(easaActions.fetchDomainTasksList(currentPage, LIMIT, parentJobId));
          enqueueSnackbar(`Job Re-Initiated successfully`, {
            variant: "success",
          })
          dispatch(easaActions.resetRetryJobStates());
      }else if(retryResponse == "failure"){
        enqueueSnackbar(`Unable to Re-Initiate Job`, {
          variant: "error",
        })
        dispatch(easaActions.resetRetryJobStates());
      }
    }
  }, [retryResponse])
  
  return (
    <div>
      <Typography component="h1" variant="h5">
        Tasks List of {domainName}
      </Typography>
      <div className="mb-2">
        <Grid container spacing={2}>
          <Grid item>
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                navigate(-2);
                localStorage.removeItem("domainDetails");
                localStorage.removeItem("jobDetails");
                dispatch(easaActions.resetJobsStates());
              }}
            >
              Domains List
            </span>
            {` > `}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
                localStorage.removeItem("jobDetails");
              }}
            >
              Jobs
            </span>
            {` > `}
            <span>Tasks</span>
          </Grid>
        </Grid>
        <div
          className="app-container domanis-table"
          style={{
            display: "flex",
            padding: "1rem",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <table className="domains-table">
            <thead>
              <tr>
                <td></td>
                <th>Task Name</th>
                <th>Status</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Job Duration</th>
                <th></th>
                {/* <th>Active</th> */}
              </tr>
            </thead>
            {domainTasks?.loading ? (
              <tbody>
                <tr>
                  <td colSpan={6}>
                    <CircularProgress size={26} color="inherit" />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {domainTasks?.list.map((job, index) => (
                  <tr
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      if(job?.updated_at) {
                        return;
                      }
                      dispatch(
                        easaActions.fetchSubTasksList(
                          1,
                          LIMIT,
                          job?.parent_job_id,
                          job?._id
                        )
                      );
                      let obj = {
                        type: job?.tool_type,
                        parentJobId: job?.parent_job_id,
                        jobId: job?._id,
                        latestParentJobId: JSON.parse(jobDetails!!).latestParentJobId
                      };
                      localStorage.setItem(
                        "taskDetails",
                        JSON.stringify(obj)
                      );
                      navigate("sub-tasks");
                      e.stopPropagation();
                    }}
                  >
                    <td>{index + 1}</td>
                    <td style={{ textAlign: "left" }}>{job?.tool_type}</td>
                    <td>{job?.status}</td>
                    <td>
                      {moment(job?.created_at).format("DD-MMM-YYYY hh:mm A")}
                    </td>
                    <td>
                      {['Initiated', 'Created'].includes(job?.status) ? '-' : moment(job?.updated_at).format("DD-MMM-YYYY hh:mm A")}
                    </td>
                    <td>{getDuration(job)}</td>
                    {(['Failed', 'Completed', 'Terminated'].includes(job?.status) ||
                    (['Created', 'Initiated'].includes(job?.status) &&
                      Math.round(job?.updated_at ? 
                      new Date(moment(new Date()).format("DD-MMM-YYYY hh:mm A")).getTime() - new Date(moment(job?.updated_at).format("DD-MMM-YYYY hh:mm A")).getTime() : 
                      new Date(moment(new Date()).format("DD-MMM-YYYY hh:mm A")).getTime() - new Date(moment(job?.created_at).format("DD-MMM-YYYY hh:mm A")).getTime() 
                      )/60000 > 10
                    )) && JSON.parse(jobDetails!!).latestParentJobId == parentJobId && <td onClick={(e)=>{
                      try {
                        e.stopPropagation();
                        dispatch(
                          easaActions.retryJob(
                            job?._id 
                          )
                        )
                        // dispatch(easaActions.fetchDomainsList(currentPage, LIMIT));
                      } catch (err) {
                        
                      }
                    }}><RefreshIcon style={{cursor:'pointer'}} /></td> || <td></td>} 
                    {/* <td>{job?.is_active ? "Yes" : "No"}</td> */}
                    {/* <td>
                      <Tooltip title="View Sub Tasks">
                        <IconButtons
                          style={{ padding: 0 }}
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(
                              easaActions.fetchSubTasksList(
                                1,
                                LIMIT,
                                job?.parent_job_id,
                                job?._id
                              )
                            );
                            let obj = {
                              type: job?.tool_type,
                              parentJobId: job?.parent_job_id,
                              jobId: job?._id,
                            };
                            localStorage.setItem(
                              "jobDetails",
                              JSON.stringify(obj)
                            );
                            navigate("sub-tasks");
                            e.stopPropagation();
                          }}
                          size="medium"
                        >
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                    </td> */}
                  </tr>
                ))}
                {!domainTasks?.list ||
                domainTasks?.list.length === 0 ||
                domainTasks.count === 0 ? (
                  <tr>
                    <td
                      colSpan={5}
                      style={{
                        fontStyle: "italic",
                        fontWeight: "normal",
                        color: "#343434",
                        padding: "0",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <span>No data available</span>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            )}
          </table>
        </div>
        {domainTasks?.count > LIMIT && (
          <Pagination
            count={Math.ceil(domainTasks?.count / LIMIT)}
            variant="outlined"
            shape="rounded"
            onChange={(e: React.ChangeEvent<unknown>, newPage: number) => {
              setCurrentPage(newPage)
              dispatch(easaActions.fetchDomainTasksList(newPage, LIMIT, parentJobId));
            }}
          />
        )}
      </div>
    </div>
  );
}

import * as React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Tooltip,
  CircularProgress,
  IconButton,
} from "@mui/material";
import Permissioned, { actions, resources } from "../permissioned";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Pagination } from "@mui/material";
import {
  Link as RouteLink,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EasaPageReducer } from "../reducers/autnhive";
import { Reducers } from "../reducers";
import * as easaActions from "../actions/easa";
import moment from "moment";
import { Visibility } from "@mui/icons-material";
import { getDuration } from "../services/helpers";
import { useSnackbar } from "notistack";

const LIMIT = 20;

export default function SubTasks() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { subTasks, retryResponse }: EasaPageReducer = useSelector<Reducers, EasaPageReducer>(
    (state) => state.autnhive.easa
  );

  const [domainName, setDomainName] = useState<string>("");
  const [jobName, setJobName] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1)
  const [jobId, setJobId] = useState("")
  const [parentJobId, setParentJobId] = useState("")
  
  const domainDetails = localStorage.getItem("domainDetails");
  const taskDetails = localStorage.getItem("taskDetails");

  useEffect(() => {
    if (domainDetails && domainDetails !== "") {
      const result = JSON.parse(domainDetails);
      setDomainName(result?.url);
    }
  }, [domainDetails]);

  useEffect(() => {
    if (taskDetails && taskDetails !== "") {
      const result = JSON.parse(taskDetails);
      setJobName(result?.type);
      if (subTasks?.list?.length === 0) {
        setJobId(result?.jobId)
        setParentJobId(result?.parentJobId)
        dispatch(
          easaActions.fetchSubTasksList(
            1,
            20,
            result?.parentJobId,
            result?.jobId
          )
        );
      }
    }
  }, [taskDetails]);

  useEffect(() => {
    return () => {
      dispatch(easaActions.resetSubTasksStates());
    };
  }, [dispatch]);

  useEffect(() => {
    if(parentJobId.length > 0){
      if(retryResponse == "success"){
        dispatch(
          easaActions.fetchSubTasksList(
            currentPage,
            LIMIT,
            parentJobId,
            jobId
          )
        );
        enqueueSnackbar(`Job Re-Initiated successfully`, {
          variant: "success",
        })
        dispatch(easaActions.resetRetryJobStates());
      }else if(retryResponse == "failure"){
        enqueueSnackbar(`Unable to Re-Initiate Job`, {
          variant: "error",
        })
        dispatch(easaActions.resetRetryJobStates());
      }
    }
  }, [retryResponse])

  return (
    <div>
      <Typography component="h1" variant="h5">
        Sub Tasks of {domainName}
      </Typography>
      <div className="mb-2">
        <Grid container spacing={2}>
          <Grid item>
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                navigate(-3);
                localStorage.removeItem("domainDetails");
                localStorage.removeItem("jobDetails");
                localStorage.removeItem("taskDetails");
                dispatch(easaActions.resetTasksStates());
              }}
            >
              Domains List
            </span>
            {` > `}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                navigate(-2);
                localStorage.removeItem("jobDetails");
                localStorage.removeItem("taskDetails");
              }}
            >
              Jobs
            </span>
            {` > `}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
                localStorage.removeItem("taskDetails");
              }}
            >
              Tasks
            </span>
            {` > `}
            <span>Sub Tasks of <b>{jobName}</b></span>
          </Grid>
        </Grid>
        <div
          className="app-container domanis-table"
          style={{
            display: "flex",
            padding: "1rem",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <table className="domains-table">
            <thead>
              <tr>
                <th>Resource ID</th>
                <th>Status</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Sub Task Duration</th>
                <th></th>
              </tr>
            </thead>
            {subTasks?.loading ? (
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <CircularProgress size={26} color="inherit" />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {subTasks?.list.map((job, index) => (
                  <tr key={index}>
                    <td>{job?.resource_id}</td>
                    <td>{job?.status}</td>
                    <td>
                      {moment(job?.created_at).format("DD-MMM-YYYY hh:mm A")}
                    </td>
                    <td>
                    {['Initiated', 'Created'].includes(job?.status) ? '-' : moment(job?.updated_at).format("DD-MMM-YYYY hh:mm A")}
                    </td>
                    <td>{getDuration(job)}</td>
                    {(['Failed', 'Completed', 'Terminated'].includes(job?.status) ||
                    (['Created', 'Initiated'].includes(job?.status) &&
                      Math.round(job?.updated_at ? 
                      new Date(moment(new Date()).format("DD-MMM-YYYY hh:mm A")).getTime() - new Date(moment(job?.updated_at).format("DD-MMM-YYYY hh:mm A")).getTime() : 
                      new Date(moment(new Date()).format("DD-MMM-YYYY hh:mm A")).getTime() - new Date(moment(job?.created_at).format("DD-MMM-YYYY hh:mm A")).getTime() 
                      )/60000 > 10
                    )) && JSON.parse(taskDetails!!)?.latestParentJobId == parentJobId &&
                    <td onClick={(e) => {
                      try {
                        e.stopPropagation();
                        dispatch(
                          easaActions.retryJob(
                            job?.job_id,
                            job?._id,
                            true
                          )
                        )
                      } catch (err) {
                        
                      }
                    }}><RefreshIcon style={{cursor:'pointer'}} /></td> || <td></td>} 
                  </tr>
                ))}
                {!subTasks?.list ||
                subTasks?.list.length === 0 ||
                subTasks.count === 0 ? (
                  <tr>
                    <td
                      colSpan={5}
                      style={{
                        fontStyle: "italic",
                        fontWeight: "normal",
                        color: "#343434",
                        padding: "0",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <span>No data available</span>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            )}
          </table>
        </div>
        {subTasks?.count > LIMIT && (
          <Pagination
            count={Math.ceil(subTasks?.count / LIMIT)}
            variant="outlined"
            shape="rounded"
            onChange={(e: React.ChangeEvent<unknown>, newPage: number) => {
              setCurrentPage(newPage)
              dispatch(
                easaActions.fetchSubTasksList(
                  newPage,
                  LIMIT,
                  parentJobId,
                  jobId
                )
              );
            }}
          />
        )}
      </div>
    </div>
  );
}
